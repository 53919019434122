@font-face {
  font-family: 'AvenirLTStd';
  src: url(./assets/fonts/AvenirLTStd-Book.otf);
}

@font-face {
  font-family: 'Avenir Heavy';
  src: url(./assets/fonts/Avenir-85-Heavy.ttf);
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box !important;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.4rem;
  font-family: 'AvenirLTStd', sans-serif;
}

svg {
  fill: #5aba5e;
}

.notification {
  /* background-color: #5aba5e; */
  /* color: #fff; */
}

.notification-bar {
  background: #fff;
}

/* SCROLLBAR */

/* width */
::-webkit-scrollbar {
  width: 5px;
  z-index: 2;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  z-index: 2;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5aba5e;
  border-radius: 10px;
  z-index: 2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5aba5e;
}

.badge {
  color: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-radius: 50px;
  padding: 5px;
  margin: 5px;
}

.cat_badge {
  width: 70.05px;
  height: 16.51px;
  background: #f4f5f5;
  font-family: 'AvenirLTStd';
  color: #6d6d6d;
  font-size: 11px;
  margin-left: 0.5rem;
}

.cat_badge:hover {
  color: #2ac940;
}

.sep {
  font-family: 'AvenirLTStd';
  color: #6d6d6d;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.ck.ck-editor__main > .ck-editor__editable {
  border-color: var(--ck-color-base-border);
  height: 800px !important;
}

.jodit-container .jodit-workplace .jodit-wysiwyg,
.jodit-container .jodit-workplace .jodit-wysiwyg_iframe,
.jodit .jodit-workplace .jodit-wysiwyg,
.jodit .jodit-workplace .jodit-wysiwyg_iframe {
  width: 100%;
  height: 100%;
  height: 750px !important;
}
